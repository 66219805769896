let body = document.querySelector('body');

window.addEventListener("DOMContentLoaded", () => {
    body.classList.remove('no-js');
});


/* Add Browser Class to HTML */
var browser_name = '';
isIE = /*@cc_on!@*/false || !!document.documentMode;
isEdge = !isIE && !!window.StyleMedia;
if (navigator.userAgent.indexOf("Chrome") != -1 && !isEdge) {
    browser_name = 'chrome';
} else if (navigator.userAgent.indexOf("Safari") != -1 && !isEdge) {
    browser_name = 'safari';
} else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser_name = 'firefox';
} else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
{
    browser_name = 'ie';
} else if (isEdge) {
    browser_name = 'edge';
} else {
    browser_name = 'other-browser';
}

let html = document.querySelector('html');

html.classList.add(browser_name);
/* End */

if(document.getElementsByClassName("accordion_headline")) {
    var acc = document.getElementsByClassName("accordion_headline");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            for (t = 0; t < acc.length; t++) {
                if(acc[t] !== this) {
                    acc[t].classList.remove("active");
                    let panel = acc[t].nextElementSibling;
                    panel.style.maxHeight = null;
                }
            }

            this.classList.toggle("active");
            let panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }
}

(function mobileTemplateImporter() {
    if(document.querySelectorAll("template")) {
        let templates = document.querySelectorAll("template");
        if(window.innerWidth < 992) {
            for (let i = 0; i < templates.length; i++) {
                let template = templates[i];
                let clon = template.content.cloneNode(true);
                template.previousElementSibling.innerHTML = "";
                template.previousElementSibling.appendChild(clon);
                template.remove();
            }
        } else {
            for (let i = 0; i < templates.length; i++) {
                let template = templates[i];
                template.remove();
            }
        }
    }
})();

let lazyLoadInstance = new LazyLoad();
lazyLoadInstance.update();

let teaserSwiper = new Swiper(".teaserSwiper", {
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
    spaceBetween: 20,
    speed: 1250,
    grabCursor: true,
    navigation: {
        nextEl: ".teaserSwiper .swiper-button-next",
        prevEl: ".teaserSwiper .swiper-button-prev",
    },
    pagination: {
        el: ".teaserSwiper .swiper-pagination",
        type: "progressbar",
    },
});

let navSwiper = new Swiper(".navSwiper", {
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
    spaceBetween: 20,
    speed: 1250,
    grabCursor: true,
    navigation: {
        nextEl: ".navSwiper .swiper-button-next",
        prevEl: ".navSwiper .swiper-button-prev",
    },
    pagination: {
        el: ".navSwiper .swiper-pagination",
        type: "progressbar",
    },
});

var courseSwiper = new Swiper(".courseSwiper:not(.two)", {
    freeMode: true,
    spaceBetween: 0,
    slidesPerView: 3,
    speed: 1250,
    grabCursor: true,
    navigation: {
        nextEl: ".courseSwiper .swiper-button-next",
        prevEl: ".courseSwiper .swiper-button-prev",
    },
    pagination: {
        el: ".courseSwiper .swiper-pagination",
        type: "progressbar",
    },
    draggable: true,
    breakpoints: {
        "300": {
            slidesPerView: 1.5,
        },
        "601": {
            slidesPerView: 2,
        },
        "1200": {
            slidesPerView: 3,
        }
    }
});

var courseSwiper = new Swiper(".courseSwiper.two", {
    freeMode: true,
    spaceBetween: 0,
    slidesPerView: 2,
    speed: 1250,
    grabCursor: true,
    navigation: {
        nextEl: ".courseSwiper .swiper-button-next",
        prevEl: ".courseSwiper .swiper-button-prev",
    },
    pagination: {
        el: ".courseSwiper .swiper-pagination",
        type: "progressbar",
    },
    draggable: true,
    breakpoints: {
        "300": {
            slidesPerView: 1.5,
        },
        "601": {
            slidesPerView: 2,
        },
    }
});
